
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { ApiCallService } from '../services/api-call.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  constructor(public fb: FormBuilder, private router: Router, private commanService: CommonService, private apiService: ApiCallService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    })
  }
  ngOnInit() {
    document.body.style.backgroundColor = "#E5E7E9";
  }

  ngOnDestroy() {
    document.body.style.background = '#fff';
  }
  submitForm() 
  {
  
    if (this.loginForm.valid) 
    {
      var head = null;
      var data = { data: this.loginForm.value, head: head }
      this.apiService.login(data).subscribe({
        next: async (result: any) => {
          if (result) 
          {
            console.log("Login Details:",result);
            if(result.status)
            {
              this.commanService.showSuccess('Login Successful!');
              window.sessionStorage.setItem('user',JSON.stringify(result.data));
              this.router.navigate(['components']);
              
              sessionStorage.setItem('token', "Dummy_Token");
              sessionStorage.setItem('key', window.btoa(JSON.stringify(result)));
            }
            else
            {
              if(result.data)
              {
                if(result.data.status==0)
                this.commanService.showError("User Inactive Contact Admin!");
                
              }
              else{
                this.commanService.showError(result.message)
              }
              
            }
            
          }
        }, error: (error: any) => {
          this.commanService.showError(error);
        }
      })
    }
  }

}


// next: async (result: any) => {
//   if (result) 
//   {
//    console.log("Login Details:",result);
//     if(result.status)             //If result contains status means it is available then check next condition
//     {  
//       if(this.loginForm.value.empId == result.user.empId && this.loginForm.value.password == result.user.password)     //For Login Form.value.empId(formControlName) == result is the value that we get from Backend
//       {
//         sessionStorage.setItem('user',JSON.stringify(result.user));         //here we have setItem into 'user' and accessed into (result.user).

//         this.router.navigate(['components']);
//       }
//     }
