export const environment = {
  production: true,
  // baseUrl:'http://localhost:3300/',
  baseUrl:'https://catalogue.primustechsys.com/',

  // baseUrl:'https://devproductcatalogue.primustechsys.com/api/',
  Cid:"1000",
  Pid:"1010",
  updateBy:"101770",
  createBy:"101770"
};