import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  constructor(private http : HttpClient, private common: CommonService) { }

  get WindowRef() { return window }
 
  login=(data:any)=> this.common.doPost('PortalLogin',data)
  getDashboarDetails = (data : any) => this.common.doGet('getDashboadDate',data);

  getCountry = (data : any) => this.common.doGet('getCountry',data);

  getUser = (data: any)=> this.common.doGet('getAllusers',data);
  deleteUserDetails = (data: any)=> this.common.doPost('deleteUserDetails',data);
  downloadUser = (data:any) => this.common.doGetBlob('exportUserExcel',data);       
  downloadUser1 = (data:any) => this.common.doGetBlob('exportUserExcelCounty',data);       //Excel file
  
  downloadUserLogs =  (data:any) => this.common.doGetBlob('getUserLogsbyID',data);
  addCategory = (data : any) => this.common.doPost('addProductCategories',data);
  getCategories  = (data : any) => this.common.doGet('getProductCategories',data);
  updateCategory = (data : any) => this.common.doPost('updateProductCategories',data);
 
  uploadProductCat = (data : any) => this.common.doPostFile('uploadProductCategory',data);
  getProductGroups = (data:any)=>this.common.doGet('getAllproductGroup',data);
  updateProductGroup = (data : any)=> this.common.doPost('updateProductGroup',data);
  addProductGroup = (data : any)=> this.common.doPost('addProductGroup',data);
  downloadProductGroup = (data : any)=> this.common.doGetBlob('exportProductGroupExcel',data);
  uploadProductGroup = (data : any) => this.common.doPostFile('uploadProductGroup',data);
  getAllSubProduct = (data:any) => this.common.doGet('getAllsubProduct',data);
  getProductGroup = (data:any) => this.common.doGet('getProductGroup',data);          //
  getSubProductGroup = (data:any) => this.common.doGet('getsubproductProductgroup',data);
  addSubProducts = (data:any) => this.common.doPost('addSubproducts',data);//
  updateSubProducts = (data:any) => this.common.doPost('updateSubproduct',data);//
  downloadSubProducts = (data:any) => this.common.doGetBlob('exportSubProductExcel',data);//
  uploadSubProduct = (data : any) => this.common.doPostFile('uploadSubProduct',data);
  getAllProducts = (data:any) => this.common.doGet('getAllProducts',data);
  addProducts = (data:any) => this.common.doPost('addProduct',data);
  updateProducts = (data:any) => this.common.doPost('updateProduct',data);
  
  uploadProducts = (data:any) => this.common.doPostFile('uploadProduct',data);
  getOEApplications = (data:any) => this.common.doGet('getAllOeApplciation',data);
  uploadOEApplication = (data:any) => this.common.doPostFile('uploadOEApplication',data);
  downloadOEApplication= (data:any) => this.common.doGetBlob('exportOEApplicatioinExcel',data);
  getManufacturer = (data:any) => this.common.doGet('getAllmanufacturer',data);
  addManufacturers = (data:any) => this.common.doPost('addManufacturer',data);
  updateManufacturers = (data:any) => this.common.doPost('updateManufacturer',data);
  downloadManufacturer = (data:any) => this.common.doGetBlob('exportManufacturerExcel',data)
  uploadManufacturer = (data:any) => this.common.doPostFile('uploadManufacturer',data);
  getUserLogs = (data: any)=> this.common.doGetBlob('getUserLogs',data);
 
  getUserLogsID= (data: any)=> this.common.doGetBlob('getUserLogsID',data);
  
 
  getAllProductByCountry= (data: any)=> this.common.doGetBlob('getAllProductByCountry',data);

  uploadImage = (data:any) => this.common.doPostFile('uploadProductImages',data);
  generateBackup = (data : any) => this.common.doGet('databaseBackup',data);

  createNotice=(data:any)=> this.common.doPost('createNotice',data)
  getNoticeBoard = (data : any) => this.common.doGet('getNoticeBoard',data);
  updateNoticeBoard=(data:any)=> this.common.doPut('updateNoticeBoard' + '/' +data.head.Id, data);
  deleteNoticeBoard=(data:any)=> this.common.doDelete('deleteNoticeBoard' + '/' +data.head.Id, data);

  addResources=(data:any)=> this.common.doPost('addResources',data)
  getaddResources = (data : any) => this.common.doGet('getaddResources',data);
  updateResources=(data:any)=> this.common.doPut('updateResources' + '/' +data.head.id, data);
  deleteResources=(data:any)=> this.common.doDelete('deleteResources' + '/' +data.head.id, data);  

 
  // deleteResources=(data:any)=> this.common.doDelete('deleteResources' + '/' +data.head.id, data);



 
 
  addYoutubeVideos=(data:any)=> this.common.doPost('addYoutubeVideos',data)
  getaddYoutubeVideos = (data : any) => this.common.doGet('getaddYoutubeVideos',data);
  updateYoutubeVideos=(data:any)=> this.common.doPut('updateYoutubeVideos' + '/' +data.head.id, data);
  deleteYoutubeVideos=(data:any)=> this.common.doDelete('deleteYoutubeVideos' + '/' +data.head.id, data);

  addCreateQR=(data:any)=> this.common.doPost('addCreateQRCode',data)
  getCreateQR = (data : any) => this.common.doGet('getCreateQRCode',data);
  QRcodeExel = (data: any)=> this.common.doGetBlob('QRcodeExel',data);
  // addProductGiftVoucher=(data:any)=> this.common.doPost('addCreateProductGiftVoucher',data)
  getProductmaster = (data : any) => this.common.doGet('getProductmaster',data);

  addProductMaster=(data:any)=> this.common.doPost('productmaster',data)
  updateProductMaster=(data:any)=> this.common.doPut('updateProductMaster' + '/' +data.head.id, data);
  getRedeemProduct = (data : any) => this.common.doGet('getRedeemProduct',data);
  updateRedeemProduct=(data:any)=> this.common.doPut('updateRedeemProduct' + '/' +data.head.id, data);
  
  UserQRScanExel = (data: any)=> this.common.doGetBlob('UserQRScanExel',data);

  ProductRedeemExel = (data: any)=> this.common.doGetBlob('ProductRedeemExel',data);

  UserRedeemExel = (data: any)=> this.common.doGetBlob('UserRedeemExel',data);
 
  exportAllUserScanQR= (data: any)=> this.common.doGetBlob('exportAllUserScanQR',data);
  userRedeemAllProductList= (data: any)=> this.common.doGetBlob('userRedeemAllProductList',data);

  

  getyoutubevideos = (data: any) => this.common.doPost('getYoutubeVideos', data);

  downloadProductCategory = (data : any) => this.common.doGetBlob('exportProductCatExcel',data);
  ProductGroupActiveInactive= (data:any) => this.common.doGetBlob('ProductGroupActiveInactive',data);
  ProductSubGroupActiveInactive= (data:any) => this.common.doGetBlob('ProductSubGroupActiveInactive',data);
  downloadProducts = (data:any) => this.common.doGetBlob('exportProductExcel',data);//
  getFeedback= (data: any)=> this.common.doGetBlob('getFeedback',data);
  
  addSlides=(data:any)=> this.common.doPost('addSlides',data)
  getaddSlides = (data : any) => this.common.doGet('getSlides',data);
  updateSlides = (data:any) => this.common.doPut('updateSlides' + '/' + data.head.id, data);
  getNewLaunchProduct= (data : any) => this.common.doPost('getNewLaunchProduct',data);
  addNewLaunchSlides=(data:any)=> this.common.doPost('addNewLaunchSlides',data)
  getNewLaunchSlides = (data : any) => this.common.doGet('getNewLaunchSlides',data);
  updateNewLaunchSlides = (data:any) => this.common.doPut('updateNewLaunchSlides' + '/' + data.head.id, data);

  addVideoSlides=(data:any)=> this.common.doPost('addVideoSlides',data)
  getVideoSlides = (data : any) => this.common.doGet('getVideoSlides',data);
  updateVideoSlides = (data:any) => this.common.doPut('updateVideoSlides' + '/' + data.head.id, data);
 
  addProductVideo=(data:any)=> this.common.doPost('addProductVideo',data)
  getaddProductVideo = (data : any) => this.common.doGet('getaddProductVideo',data);
  updateProductVideo=(data:any)=> this.common.doPut('updateProductVideo' + '/' +data.head.id, data);
  deleteProductVideo=(data:any)=> this.common.doDelete('deleteProductVideo' + '/' +data.head.id, data);

  updateProductExecel = (data:any) => this.common.doPost('updateProductExecel',data);
  // downloadProductCategory
  // ProductGroupActiveInactive
  // ProductSubGroupActiveInactive
  // downloadProducts
  // getFeedback
  // addSlides
  // getaddSlides
  // updateSlides
  // getNewLaunchProduct
  // addNewLaunchSlides
  // getNewLaunchSlides
  // updateNewLaunchSlides
  // addVideoSlides
  // getVideoSlides
  // updateVideoSlides
  // addProductVideo
  // getaddProductVideo
  // updateProductVideo
  // deleteProductVideo
}


// User Registration
// Product Search
// Visited About Us Page
// Visited Contact Us Page
// Visited Individual Product
// Visited New Launch Page
// Visited Product Catalogue Page
// Visited Product Catalouge Page
// Visited Product Category
// Visited Product Group
// Visited Scanner Page
// Visited Sub Product
// Visited Videos Page



