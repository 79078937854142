import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mainoutlet',
  templateUrl: './mainoutlet.component.html',
  styleUrls: ['./mainoutlet.component.scss']
})
export class MainoutletComponent implements OnInit {
  onActivate(e:any, outlet:any){
    window.scrollTo(0, 0);
  }
  constructor() { }

  ngOnInit() {
  }

}
